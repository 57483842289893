import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '40, 22, 111',
		'primary-dark': '20, 7, 72',
		'accent': '0, 147, 221',
		'accent-plus': '255, 255, 255',
	},
});
